import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { Contact } from '../components/Contact'

const ContactPage = ({ data }) => {
  const { title: siteTitle } = data.site.siteMetadata
  const {
    contact_title: ContactTitle,
    contact_intro_text: ContactIntroText,
    meta_description: metadesc,
    meta_tags: metatags,
  } = data.wordpressPage.acf
  const { title: pageTitle } = data.wordpressPage

  return (
    <Layout>
      <Helmet>
        <title>{`${pageTitle} | ${siteTitle}`}</title>
        {metadesc ? <meta name="description" content={metadesc} /> : null}
        {metatags ? <meta name="keywords" content={metatags} /> : null}
      </Helmet>
      <Contact
        ContactTitle={ContactTitle}
        ContactIntroText={ContactIntroText}
      />
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query contactMetaQuery {
    site {
      siteMetadata {
        title
      }
    }
    wordpressPage(wordpress_id: { eq: 16 }) {
      title
      acf {
        contact_title
        contact_intro_text
        meta_description
        meta_tags
      }
    }
  }
`
