import React from 'react'

const ContactForm = () => {
  return (
    <form
      name="contact"
      method="POST"
      action="/contact-bedankt"
      data-netlify="true"
      netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="contact" />
      <div className="hidden">
        <label htmlFor="bot-field">
          Don’t fill this out if you're human:
          <input id="bot-field" name="bot-field" />
        </label>
      </div>
      <div className="field">
        <label htmlFor="name">Uw naam:</label>
        <input className="input" type="text" id="name" name="name" required />
      </div>
      <div className="field">
        <label className="label" htmlFor="email">
          Uw e-mail:
        </label>
        <input
          className="input"
          type="email"
          id="email"
          name="email"
          required
        />
      </div>
      <div className="field">
        <label className="label" htmlFor="message">
          Boodschap:
        </label>
        <textarea className="textarea" id="message" name="message" />
      </div>
      <div className="field">
        <button type="submit" className="cta-link-contact">
          Verzenden
        </button>
      </div>
    </form>
  )
}

export default ContactForm
