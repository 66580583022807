import React from 'react'
import { PropTypes } from 'prop-types'
import { FaLinkedin } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import ContactForm from './ContactForm'

const iconStyles = {
  color: '#444',
  className: 'social-share-icon-contact',
}

export const Contact = ({ ContactTitle, ContactIntroText }) => {
  return (
    <>
      <section className="enereno-intro section color-bg">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-8 has-text-centered">
              <h1 className="title">{ContactTitle}</h1>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: ContactIntroText }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section contact">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-10-mobile is-full-tablet is-two-thirds-desktop">
              <div className="columns">
                <div className="column is-6">
                  <h2>Contact</h2>
                  <div className="content">
                    <p>
                      Johan Bijn
                      <br />
                      Bijn Interieurarchitectuur
                      <br />
                      Pieter Hellebautstraat 4<br />
                      9031 Drongen
                    </p>
                    <p>
                      <a href="tel:+32 (0)473 81 36 55">+32 (0)473 81 36 55</a>
                      <br />
                      <a href="mailto:johan@bijninterieur.be">
                        johan@bijninterieur.be
                      </a>
                    </p>
                    <p>BE 0664 997 950</p>
                    <p>
                      <a
                        href="https://www.linkedin.com/in/johanbijn/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <IconContext.Provider value={iconStyles}>
                          <FaLinkedin />
                        </IconContext.Provider>
                      </a>
                    </p>
                  </div>
                </div>
                <div className="column is-6">
                  <h2>Heb je een vraag?</h2>
                  <div className="contact-form">
                    <ContactForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

Contact.propTypes = {
  content: PropTypes.string,
  quote: PropTypes.string,
}

export default Contact
